.builder-container {
  background-color: #1e1e1e;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
  z-index: 1;
}

.builder-container > article {
  grid-column: 2 / 12;
}

.builder-container > article > h1 {
  text-align: center;
  margin-top: 40px;
}

.builder-type-container {
  margin-bottom: 56px;
}

.builder-type-container > h3 {
  margin-bottom: 16px !important;
}

/* switcher */

div.switcher {
  display: flex;
  background-color: #3d3d3d;
  border-radius: 20px;
  width: fit-content;
  padding: 8px 4px 8px 4px;
  margin: 0 auto;
  margin-top: 24px !important;
}

div.switcher > div {
  padding: 8px 32px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 4px;
  color:#ffffff;
}

div.switcher > div:hover {
  background-color: #1e1e1e;
}

div.switcher > div.active {
  background-color: #2d62ed;
}

div.switcher > div > span:first-of-type {
  font-size: 12px;
  text-transform: uppercase;
}

div.switcher > div > span {
  font-size: 20px;
  font-weight: 600;
}

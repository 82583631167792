.modal-builder-card {
  position: relative;
  height: 340px;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  border: 1px solid #d8d8d8;
  background-position: top center !important;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.71, 0, 0.26, 1.3), backdrop-filter 0s;
}

.modal-builder-card:hover {
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.1);
}

.modal-builder-card .modal-builder-card-img {
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  height: calc(100% - 100px);
}

.modal-builder-card .productInfo {
  /* position: absolute; */
  /* bottom: 0; */
  width: calc(100% - 0.5px);
  transition: all 0.2s cubic-bezier(0.71, 0, 0.26, 1.3), backdrop-filter 0s;
  border-top: 1px solid #e9e9e9;
  border-radius: 0 0 16px 16px;
  padding: 24px 16px;
  /* height: 110px; */
  background-color: #ffffffaa;
  backdrop-filter: blur(24px) saturate(300%) brightness(120%);
  overflow: hidden;
}

.modal-builder-card .productInfo h6 {
  font-size: 18px;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 54px);
}

.modal-builder-card .productInfo h6:hover {
  text-decoration: underline;
}

.productInfo button {
  margin: 0 auto;
  width: 100%;
}

.modal-component-rating {
  display: inline;
  float: right;
  margin-left: 8px;
}

.modal-component-rating > span:first-of-type {
  color: #2d62ed;
}

.modal-component-rating > span:nth-of-type(2) {
  color: #181818;
  margin-left: 4px;
}

.modal-builder-card:hover .productInfo {
  height: 148px;
}

.productInfo {
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: transform 0.3s ease;
  border-top: 1px solid #E9E9E9;
  padding: 24px;
  height: 100px;
  background-color: #ffffff70;
  backdrop-filter: blur(16px);
  overflow:hidden;
}

.productInfo .price {
  margin-right: 16px;
  color: #00a266;
  font-weight: 600;
  font-size: 18px;
}

.productInfo .discount {
  color: var(--lt-color-gray-600);
  text-decoration: line-through;
  font-size: 16px;
}

.modal-builder-card .productInfo .hoverButton {
  opacity: 0;
  padding-top: 10px;
  margin-top: 0px;
  width: 100%;
  pointer-events: none;
  transition: all 0.2s cubic-bezier(0.71, 0, 0.26, 1.3);
}

.modal-builder-card:hover .productInfo .hoverButton {
  opacity: 1;
  pointer-events: auto;
  margin-top: 8px;
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1270px) {
}

@media only screen and (max-width: 940px) {
  .modal-builder-card {
    margin-bottom: 15px;
  }
  .modal-builder-card .modal-builder-card-img {
    height: calc(100% - 148px);
  }
  .productInfo {
    height: auto!important;
  }
  .modal-builder-card .productInfo .hoverButton {
    opacity: 1;
    pointer-events: auto;
    margin-top: 8px;
  }
  
}

.component-row-builder > tr > td:first-of-type:not([colspan="8"]) {
  padding: 28px 0px 28px 24px;
}

.component-row-builder > tr > td:last-of-type:not([colspan="8"]) {
  width: 0;
}

.component-row-builder > tr {
  min-height: 50px;
  position: relative;
}

.component-row-builder::before {
  height: 24px;
  display: table-row;
  content: "";
}

.component-header-extra::before {
  height: 48px;
  display: table-row;
  content: "";
}

.add-component {
  border-radius: 20px;
  border: 3px dashed #3d3d3d;
  padding: 24px;
}

.add-component > .empty {
  display: flex;
  align-items: center;
}

.add-component > .empty > h4 {
  position: absolute;
}

.add-component > .empty > button {
  margin: 0 auto;
}

.component-type {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.component-title {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.change-component {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  right: -40px;
}

.change-component > button {
  margin: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px !important;
}

.change-component > button.ant-btn-circle.ant-btn {
  width: 32px !important;
  padding: 0 !important;
  background: #3d3d3d;
  border: none;
}

.change-component > button.ant-btn-circle.ant-btn svg {
  fill: #fff;
}

@media only screen and (max-width: 940px) {
}

.change-component > button.ant-btn-circle.ant-btn-dangerous:hover {
  background: #f75578;
  border-color: #f75578;
}

.change-component > button.ant-btn-circle > .anticon {
  line-height: 0;
}

.modal-builder-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 24px;
  grid-template-areas:
    "toggle search"
    "filterbar content";
  height: 70vh;
}

/* filter bar */

.modal-builder-filterbar {
  /* grid-area: filterbar; */
  overflow: auto;
  height: 99%;
  padding-right: 8px;
}

.modal-builder-filterbar > div > h6 {
  color: #181818;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 24px !important;
}

.modal-builder-filterbar > div {
  border-bottom: 1px #d8d8d8 solid;
  padding: 16px 0;
}

.modal-builder-filterbar > div:first-of-type {
  padding-top: 0;
}

.modal-builder-filterbar > div:last-of-type {
  border-bottom: none;
}

/* toggle */

.ant-switch-checked {
  background-color: #2d62ed;
}

/* slider */

.modal-builder-filterbar .filter-prize .ant-slider-track {
  background-color: #7f7f7f;
}

.modal-builder-filterbar .filter-prize .ant-slider-handle,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #2d62ed;
  border-width: 3px;
}

.ant-slider-handle:focus,
.ant-slider-handle-dragging {
  box-shadow: 0 0 0 5px rgba(45, 98, 237, 0.12) !important;
}

.modal-builder-filterbar .filter-prize .ant-slider-handle:hover,
.modal-builder-filterbar
  .filter-prize
  .ant-slider-track:hover
  .ant-slider-handle {
  background-color: #2d62ed;
}

/* checkbox */

.ant-checkbox-checked .ant-checkbox-inner {
  border: #2d62ed;
  background-color: #2d62ed;
}

.ant-checkbox-checked::after,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #2d62ed;
}

/* rating */

.filter-rating label {
  width: 100%;
}

.modal-builder-content {
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  overflow: visible;
  height: 99%;
  padding-right: 8px;
}

.modal-builder-toggle {
  grid-area: toggle;
}

.modal-builder-search {
  grid-area: search;
  width: 30%;
}

.modal-builder-search > span {
  border-radius: 20px;
}

a.buy-external {
  display: flex;
  width: max-content;
  flex-direction: row-reverse;
  align-items: center;
  padding-top: 4px !important;
  padding-bottom: 5px !important;
  margin: 0 auto;
  color: #ffffff;
  border: none !important;
}

a.buy-external:hover,
a.buy-external:active,
a.buy-external:focus {
  background: #2d62ed;
  color: white !important;
}

/* single details info for component */

.component-detailer-scrollcontainer {
  height: 60vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  overflow: auto;
}

.component-detailer-scrollcontainer .component-img {
  background-size: contain !important;
  background-repeat: no-repeat;
  border: 1px solid #e9e9e9;
}

.component-detailer-metadata {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.component-detailer-metadata,
.component-detailer-container {
  border-radius: 20px;
  border: 1px solid #e9e9e9;
  padding: 24px;
}

.component-detailer-container {
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.component-detailer-description > h5,
.component-detailer-specifications > h5 {
  color: #181818;
  margin-bottom: 16px !important;
}

.ant-descriptions-item-label,
.ant-descriptions-item-content {
  color: #181818;
}

.component-detailer-metadata,
.component-detailer-container {
  margin-right: 16px;
}

.sub-metadata {
  display: flex;
  align-items: center;
}

.sub-metadata p {
  margin-bottom: 0;
}

.sub-metadata p:first-of-type {
  margin-left: 8px;
}

.sub-metadata ul.ant-rate {
  color: #2d62ed;
  font-size: 14px;
}

.sub-metadata ul.ant-rate .ant-rate-star {
  margin-right: 2px;
}

.sub-metadata p {
  margin-top: 4px !important;
}

.sub-metadata > span {
  margin: 0 8px;
  color: #181818;
}

.modal-pricing {
  font-weight: 800;
  font-size: 32px;
  color: #00a266 !important;
  display: block;
  margin-top: 32px;
}

.modal-pricing > span:first-of-type {
  font-weight: normal;
  color: #7f7f7f;
  text-decoration: line-through;
  margin-left: 24px;
  font-size: 20px;
}

.modal-pricing > span:nth-of-type(2) {
  font-weight: normal;
  color: #7f7f7f;
  margin-left: 16px;
  font-size: 16px;
}

/* image container  */

.preview-images-container {
  margin-top: 16px;
}

.preview-images-container div.ant-image {
  margin-right: 16px;
}

.preview-images-container div.ant-image img {
  border-radius: 8px;
}

/* filters */

.builderModal div.ant-modal-content div.ant-modal-body {
  overflow: scroll;
}

.accordionFilter {
  height: fit-content;
  grid-area: filterbar;
}

/* button */

.component-detailer-metadata button {
  margin-top: 16px;
  min-width: 60%;
}

.delete-button-text {
  display: none !important;
}

.edit-button-text {
  display: none !important;
}

.editButton .ant-btn-icon {
  margin-right: 0px !important;
}

.deleteButton .ant-btn-icon {
  margin-right: 0px !important;
}

@media only screen and (max-width: 940px) {
  .modal-builder-container {
    display: flex;
    flex-direction: column;
    height: 70vh;
  }

  .modal-builder-content {
    grid-template-columns: 1fr;
    display: block;
  }

  .component-row-builder
    > tr.componentFilled-true
    > td
    > .change-component
    > button {
    width: 100% !important;
    border-radius: 16px;
    color: #3d3d3d;
  }

  .editButton {
    background-color: #3d3d3d !important;
    color: #fff !important;
  }

  .deleteButton {
    background-color: #3d3d3d !important;
    color: #fff !important;
  }
}

@media screen and (max-width: 768px) {
  table > thead > tr > th {
    display: none;
  }
  table > thead > tr > th:first-child {
    display: block;
    padding-left: 0 !important;
    max-width: 100% !important;
  }
  .component-row-builder > tr.componentFilled-true {
    background: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 8px 16px;
    padding: 16px;
    border-radius: 16px;
  }
  .component-row-builder > tr.componentFilled-true > td.component-type-title {
    display: none;
  }

  .delete-button-text {
    display: block !important;
    color: #fff !important;
  }

  .edit-button-text {
    display: block !important;
    color: #fff !important;
  }

  /* Image */
  .component-row-builder > tr.componentFilled-true > td:nth-of-type(3) {
    grid-area: 1 / 1 / span 1 / span 2;
  }

  .component-row-builder > tr > td > img {
    height: 100% !important;
    width: 100% !important;
  }

  /* Title */
  .component-row-builder > tr.componentFilled-true > td.component-title {
    font-weight: bold;
    grid-area: 1 / 3 / span 1 / span 4;
    align-self: center;
    max-width: 100%;
    /* background-color: red!important; */
  }

  /* Regular price */
  .component-row-builder > tr.componentFilled-true > td:nth-of-type(4) {
    grid-area: 2 / 1 / span 1 / span 3;
    /* background-color: green!important; */
  }
  .component-row-builder > tr.componentFilled-true > td:nth-of-type(4)::before {
    content: "Price ";
    white-space: pre;
    display: block;
    color: #7f7f7f;
    font-weight: 600;
  }

  /* Discount */
  .component-row-builder > tr.componentFilled-true > td:nth-of-type(5) {
    grid-area: 2 / 4 / span 1 / span 3;
    /* background-color: blue!important; */
  }
  .component-row-builder > tr.componentFilled-true > td:nth-of-type(5)::before {
    content: "Discount ";
    white-space: pre;
    display: block;
    color: #7f7f7f;
    font-weight: 600;
  }

  /* New price */
  .component-row-builder > tr.componentFilled-true > td:nth-of-type(6) {
    grid-area: 3 / 1 / span 1 / span 3;
    color: #00ae0c !important;
    font-weight: 600;
    font-size: 1.125rem;
    /* background-color: orange!important; */
  }
  .component-row-builder > tr.componentFilled-true > td:nth-of-type(6)::before {
    content: "Total ";
    white-space: pre;
    display: block;
    color: #7f7f7f;
    font-weight: 600;
    font-size: 1rem;
  }

  /* Button */
  .component-row-builder > tr.componentFilled-true > td:nth-of-type(7) {
    grid-area: 3 / 4 / span 1 / span 3;
    align-self: center;
    /* background-color: purple!important; */
  }

  .component-row-builder > tr.componentFilled-true > td:nth-of-type(7) > a {
    width: 100%;
    background: #2d62ed;
    color: white;
  }

  .component-row-builder > tr.componentFilled-true > td:nth-of-type(8) {
    grid-area: 4 / 1 / span 1 / span 6;
    /* background-color: yellow!important; */
    width: auto !important;
  }

  .component-row-builder > tr.componentFilled-true > td > .change-component {
    flex-direction: row;
    gap: 16px;
    right: 0;
  }
  .component-row-builder
    > tr.componentFilled-true
    > td
    > .change-component
    > button {
    width: 100% !important;
    border-radius: 16px;
    color: #3d3d3d;
  }
}

@media screen and (max-width: 576px) {
  .add-component > .empty > h4 {
    display: none;
  }
  .change-component > button.ant-btn-circle.ant-btn svg {
    fill: #fff;
  }
  .modal-builder-search {
    width: 100%;
  }
}
/* component not found  */

.item-not-found {
  grid-area: 1 / 2;
}

body {
  font-size: 16px;
  background-color: #0f0f0f;
}

.wrapper {
  background-color: #0f0f0f;
  margin-top: 170px !important;
  margin-bottom: 120px !important;
  padding: 0 16px;
}

section,
.template-padding {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
  max-width: 1200px;
}

.wrapped-container {
  grid-column: 1 / 13;
}

.wrapper-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
  margin-bottom: 10vh;
}

.card {
  background-color: rgba(8, 17, 136, 0.35);
  backdrop-filter: blur(5px);
  height: 30vh;
  width: auto;
  border-radius: 8px;
  padding: 24px;
}

@media only screen and (max-width: 1270px) {
  section,
  .template-padding {
    max-width: 900px;
  }
  .wrapper {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
}

@media only screen and (max-width: 940px) {
  section,
  .template-padding {
    max-width: 800px;
  }
}

.discover {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
  margin-bottom: 10vh;
  padding: 0;
  margin-top: 40px;
}

.testCard {
  width: 100%;
  height: auto;
  background-color: rgba(8, 17, 136, 0.35);
  backdrop-filter: blur(5px);
  padding: 16px;
  border-radius: 8px;
}

.testCard img {
  width: 100%;
  height: auto;
  margin-bottom: 16px;
  border-radius: 8px;
}

label {
  color: #181818;
  margin-top: 12px;
  display: inline-block;
}

h6 {
  margin-top: 16px;
}

.submit-product {
  margin-top: 24px;
}

.general-info-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
}

.images-grid {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: scroll;
  height: 300px;
}

div.build-card {
  background-color: white;
  backdrop-filter: blur(5px);
  border-radius: 20px;
  display: flex;
  flex-direction: row-reverse;
  margin-top: -100px;
}

div.build-card:nth-of-type(3) {
  grid-column: 7 / 12;
}

div.build-card:nth-of-type(2) {
  grid-column: 2 / 7;
}

.build-card-image {
  width: 75%;
  border-radius: 8px;
}

div.build-card-content {
  padding: 30px 24px 40px 40px;
}

div.build-card-content > span {
  color: #2d62ed;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

div.build-card-content h3 {
  font-weight: bold;
  color: #181818;
  line-height: 1.2;
}

div.build-card-content p {
  color: #181818;
  margin-top: 10px;
}

div.build-card-content button a {
  color: white;
}

div.build-cta {
  margin-top: 24px;
}

div.home-container button {
  margin-top: 20px;
}

@media only screen and (max-width: 1440px) {
  
}

@media only screen and (max-width: 1270px) {
  div.build-card:nth-of-type(3) {
    grid-column: 7 / 13;
  }

  div.build-card:nth-of-type(2) {
    grid-column: 1 / 7;
  }
}

@media only screen and (max-width: 940px) {
  div.build-card {
    margin-top: 0px;
  }

  div.build-card:nth-of-type(2),
  div.build-card:nth-of-type(3) {
    grid-column: 1 / 13;
  }
}

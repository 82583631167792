/* @import "~antd/dist/antd.css"; */

/* Fonts */

@font-face {
  font-family: "Avenir Next";
  src: url(./assets/fonts/AvenirNext-Regular-08.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next";
  src: url(./assets/fonts/AvenirNext-Bold.otf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next";
  src: url(./assets/fonts/AvenirNext-DemiBold-03.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Body */

body {
  color: white;
}

.home-body {
  padding: 32px;
  height: 100vh;
  background-color: #0f0f0f;
}

/* force header styling */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ffffff;
  font-weight: bold !important;
  letter-spacing: 1px;
  margin-bottom: 8px !important;
  line-height: 1.3em;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

p {
  font-weight: normal;
  font-family: 'Avenir Next', sans-serif;
}

a {
  text-decoration: none;
}

/* Navigation */

nav {
  background-color: #181818;
  padding: 14px 14px;
}

nav > div {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

th {
  color:white;
}

li {
  color:#ffffff;
}

.icon-left svg {
  margin-left: 0 !important;
}

.logo {
  height: auto;
  width: 50%;
}

.nav-items {
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-bottom: 0;
}

.nav-items li {
  margin-left: 16px;
}

.nav-items li:first-child {
  display: flex;
  align-items: center;
}

.dropdown-icon svg {
  transform: rotate(0deg);
  transition: all 0.2s cubic-bezier(0.68, -0.3, 0.265, 1.3);
  -moz-transition: all 0.2s cubic-bezier(0.68, -0.3, 0.265, 1.3);
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.3, 0.265, 1.3);
}

.dropdown-icon.open svg {
  /* -moz-transform: rotate(180deg); */
  /* -webkit-transform: rotate(180deg); */
  transform: rotate(180deg);
  transition: all 0.2s cubic-bezier(0.68, -0.3, 0.265, 1.3);
  -moz-transition: all 0.2s cubic-bezier(0.68, -0.3, 0.265, 1.3);
  -webkit-transition: all 0.2s cubic-bezier(0.68, -0.3, 0.265, 1.3);
}

.products-menu {
  display: flex !important;
  align-items: center !important;
  flex-direction: row-reverse !important;
}

.products-menu > span svg {
  margin-bottom: 2px;
}

/* nav item products dropdown */

.nav-item-products-dropdown {
  padding: 16px !important;
  display: flex;
}

.nav-item-products-dropdown-group {
  min-width: 320px;
}

.nav-item-products-dropdown h4 {
  color: #181818;
}

.nav-item-products-dropdown > div:last-child ul li {
  color: #181818;
  list-style-type: none;
}

/* mobile default settings */

.mobile-nav-item {
  display: none;
}

.mobile-nav-item > button {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.mobile-nav-item > button > span {
  margin-left: 8px;
}

/* testing cards purposes */

.nav-browse-styles {
  padding: 24px;
  border-radius: 4px;
}

.nav-browse-styles h5 {
  text-shadow: 0px 0px 8px rgba(150, 150, 150, 1);
}

.nav-browse-1 {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://i.redd.it/lza9vo04eaq21.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.nav-browse-2 {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://68keys.io/images/board/diodes-assembled.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.nav-browse-3 {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://mgrgaming.co.za/wp-content/uploads/Mechanical-Keyboard-Switches-We-Explain-The-Different-Types-And-Their-Uses_1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

.nav-browse-4 {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://candykeys.com/img/gb/mizu/2x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* password requirements tooltip */

.password-requirements {
  padding: 0 16px;
}
.password-requirements > h6 {
  color: #181818;
}

/* override ant design style force */

.ant-notification-notice-message,
.ant-notification-notice-description {
  color: #181818 !important;
}

.ant-pagination-item a {
  color: #181818 !important;
}

.ant-pagination-item-active a {
  color: #f75578 !important;
}

div.header-nav {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

div.header-nav a {
  height: 44.43px;
}

.ant-message {
  color: #181818 !important;
}

.ant-dropdown {
  position: fixed !important;
}

.ant-dropdown .ant-dropdown-menu {
  border-radius: 20px;
  padding-bottom: 16px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 6px 24px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:focus {
  background: #2d62ed;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:first-of-type:hover {
  background: transparent;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:last-of-type:hover {
  background: #f75578;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover span,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover span a {
  color: white !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  color: #181818 !important;
}

.ant-dropdown-menu-item > .anticon + span > a,
.ant-dropdown-menu-submenu-title > .anticon + span > a {
  color: #181818 !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff !important;
}

.ant-avatar {
  margin-left: 24px !important;
}

.ant-modal-title {
  font-weight: 600 !important;
  font-size: 20px !important;
}

.ant-drawer-title {
  color: #181818 !important;
}

.ant-menu {
  color: #f75578 !important;
}

.ant-btn {
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 8px 24px 6px 24px !important;
  height: auto !important;
  line-height: normal;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

button.ant-btn-block {
  justify-content: center;
}

button.ant-btn-default svg {
  fill: #2d62ed;
  transition-duration: 0.3s;
}

button.ant-btn-default {
  fill: #2d62ed;
  transition-duration: 0.3s;
  background-color: #fff;
  border: 2px solid #2d62ed;
  color:#2d62ed;
}

button.ant-btn-default:hover,
button.ant-btn-default:focus,
button.ant-btn-default:active {
  background: #2d62ed!important;
  color: white!important;
  border-color: #2d62ed!important;
}

button.ant-btn-default.ant-btn-dangerous:hover,
button.ant-btn-default.ant-btn-dangerous:focus,
button.ant-btn-default.ant-btn-dangerous:active {
  background: #f75578!important;
  color: white!important;
  border-color: #f75578!important;
}

button.ant-btn-default:hover svg,
button.ant-btn-default:focus svg,
button.ant-btn-default:active svg {
  fill: white;
  transition-duration: 0.3s;
}

button.ant-btn-ghost {
  color: white;
  border-color: white;
}

button.ant-btn-ghost:hover,
button.ant-btn-ghost:focus,
button.ant-btn-ghost:active {
  background: #2d62ed;
  color: white;
  border-color: #2d62ed;
}

button.ant-btn-ghost span {
  margin: 0 !important;
}

.default-nav-buttons {
  background: #3d3d3d !important;
  color: white !important;
  border: 2px solid transparent !important;
  padding: 6px 22px 4px 22px !important;
}

.default-nav-buttons.builder-button {
  background: #f75578 !important;
  color: white !important;
  border: 2px solid transparent !important;
}
.default-nav-buttons.builder-button:hover {
  background: #ff8097 !important;
  border: 2px solid transparent !important;
}

.default-nav-buttons span {
  margin: 0 !important;
}

.default-nav-buttons svg {
  fill: white !important;
}

.default-nav-buttons:hover {
  border: 2px solid white !important;
}

.ant-modal-content {
  border-radius: 20px !important;
}

.ant-modal-header {
  border-radius: 20px 20px 0 0 !important;
}

.ant-modal-body {
  border-radius: 0 0 20px 20px !important;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.ant-modal-footer > button {
  border-radius: 100px !important;
}

.ant-modal-footer > button:first-of-type:hover,
.ant-modal-footer > button:first-of-type:focus,
.ant-modal-footer > button:first-of-type:active {
  border-radius: 100px;
  background: #2d62ed;
  color: white;
  border-color: #2d62ed;
}

.ant-input {
  border-radius: 10px !important;
}

.ant-modal-title {
  text-align: center !important;
}

.ant-modal-body p {
  color: #181818 !important;
}

.ant-popover-inner-content {
  color: #181818 !important;
}

.ant-checkbox-wrapper {
  color: #181818 !important;
}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {...} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {...} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */

@media only screen and (max-width: 1270px) {
  nav > div {
    max-width: 900px;
  }
}

@media only screen and (max-width: 940px) {
  /* change the nav to be mobile friendly TBD */

  nav > div {
    max-width: 800px;
  }

  .mobile-nav-item {
    display: block !important;
  }

  .nav-items li {
    display: none;
  }

  .nav-items li:first-child {
    display: none;
  }
}

.d-none {
  display:none!important;
}

/* @media only screen and (max-width: 992px) {
  nav > div {
    max-width: 800px;
  }
} */

/* Margin Utilities */
.m-0 { margin: 0 !important; }
.m-1 { margin: 0.25rem !important; }
.m-2 { margin: 0.5rem !important; }
.m-3 { margin: 0.75rem !important; }
.m-4 { margin: 1rem !important; }


.process-card {
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.process-card > div {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.process-card > div > span {
    text-align: center;
    color: #2d62ed;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
}

.process-card > div > h4 {
    color: #181818;
    text-align: center;
}

.process-card > div:nth-of-type(2) {
    height: 175px;
    border-radius: 0 0 20px 20px;
    background-size: 175%;
    background-repeat: no-repeat;
    background-position: 24px top;
}

.sum-component {
  background-color: #3d3d3d;
  border-radius: 20px;
  position: sticky;
  position: -webkit-sticky;
  bottom: 32px;
  padding: 16px;
  width: max-content;
  align-self: flex-end;
  margin-right: 8.5%;
  -webkit-box-shadow: 0px 0px 13px 3px rgba(24, 24, 24, 0.5);
  box-shadow: 0px 0px 13px 3px rgba(24, 24, 24, 0.5);
  display: flex;
  align-items: center;
  z-index: 1000;
  color: #ffffff;
}

.sum-component button {
  background-color: "#2d62ed";
  color: #ffffff;
  border: none;
}

.sum-component button:hover {
  background-color: #ffffff !important;
  color: #2d62ed !important;
  border: none;
}

.sum-component > p {
  /* display: flex; */
  margin-right: 16px;
  margin-bottom: 0 !important;
}

.auth-container {
  background-color: #1e1e1e;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
  z-index: 0;
}

.auth-container > .auth-banner {
  grid-column: 1 / 6;
  background-color: #2d62ed;
  border-radius: 20px 0 0 20px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.auth-container > .auth-banner > div > h3 {
  line-height: 1.2;
  margin-bottom: 16px !important;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.459);
}

.auth-container > .auth-inner-container {
  grid-column: 7 / 12;
  padding: 72px 0;
}

.auth-banner > div:first-of-type {
  display: flex;
  flex-direction: column;
  margin: 32px 0;
  z-index: 12;
}

.auth-banner > img {
  position: relative;
  z-index: 0;
}

.auth-banner > img:last-of-type {
  bottom: 100px;
}

.auth-banner > img:first-of-type {
  align-self: flex-end;
  top: 16px;
}

.auth-container > .auth-inner-container > div:first-of-type {
  background-color: white;
  border-radius: 20px;
  padding: 40px;
}

.auth-container > .auth-inner-container > div:first-of-type > h1,
.auth-container > .auth-inner-container > div:first-of-type > p {
  color: #181818;
}

.auth-pattern {
  width: 240px;
  /* position: relative; */
}

@media only screen and (max-width: 940px) {
  .auth-container > .auth-banner {
    grid-column: 1 / 13;
    border-radius: 20px;
    grid-row: 2 / 3;
  }

  .auth-container > .auth-inner-container {
    grid-column: 1 / 13;
    grid-row: 1/2;
    padding: 0;
  }
}

div.guide-page-card {
    background-color: white;
    backdrop-filter: blur(5px);
    width: auto;
    border-radius: 20px;
    height: 242px;
    padding:0;
    flex:1;
  }
  
  div.guide-page-card > div {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }
  div.guide-extra {
    flex:1;
    flex-direction: row;
  }

  div.guide-extra .guide-card-image  {
    width:36%;
  }
  div.guide-page-card-image {
    height: 100%;
    width: 35%;
    background-size: cover!important;
    border-top-right-radius: 20px;
border-bottom-right-radius: 20px;
  }
  
  div.guide-page-card-page-content {
    max-width: 50%;
    padding:40px;
  }
  
  div.guide-page-card-page-content h3 {
    font-size:22px;
    color:#181818;
  }

  div.guide-page-card-page-content p {
    color: #181818;
    font-size: 16;
  }
  
  div.guide-page-card-page-content > span {
    color: #2d62ed;
    font-weight: bold;
    text-transform: uppercase;
    font-size:12px;
  }
  
  .ant-timeline-item-head-blue {

    color:#2D62ED;
    border-color:#2D62ED;
  }
  .ant-timeline-item-tail {
    border-color:#2D62ED!important;
  }

  .ant-timeline-item-head {
    background-color:transparent!important;
  }

  @media screen and (max-width: 576px) {
    div.guide-page-card {
      height:100%;
    }
    div.guide-page-card > div {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: unset;
    }
    div.guide-page-card-page-content {
      padding:20px;
      min-width: 100%;
    }
    div.guide-page-card-page-content p {
      display:none;
    }
    div.guide-page-card-image {
      border-bottom-right-radius:0px;
      width:100%;
      height:128px;
      border-top-left-radius: 20px;
    }
    div.guide-extra .guide-card-image {
      width:100%;
    }
    div.guide-page-card-page-content > span {
      font-size:15px;
    }
    .ant-btn-round {
      width:100%;
    }
  }
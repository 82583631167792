div.community-card {
  background-color: rgba(8, 17, 136, 0.35);
  backdrop-filter: blur(5px);
  width: auto;
  border-radius: 8px;
  margin: 8px;
}

div.community-build-image {
  height: 240px;
  border-radius: 8px 8px 0 0;
}

div.community-card-content {
  padding: 24px;
}

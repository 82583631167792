div.wrapper section:nth-of-type(1) {
  padding-bottom: 120px;
}

.CTA_button {
  margin-top: 20px;;
}

div.wrapper section.home-section-1 div.fold-section {
  height: 300px;
  background-color: #2d62ed;
  border-radius: 16px;
  padding: 32px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1 / 13;
}

div.wrapper section.home-section-1 div.fold-section h1 {
  /* font-size: 56px; */
  text-align: center;
}

div.wrapper section.home-section-1 div.fold-section > p {
  text-align: center;
  width: 60%;
  margin-bottom: 64px;
  font-size: 18px;
  font-weight: 600;
}

div.wrapper section.home-section-1 div.build-section {
  grid-column: 2 / 12;
}

.home-main-cta {
  margin-top: 64px;
}

div.wrapper section.home-section-2 > div {
  background-color: #1e1e1e;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
  grid-column: 1 / 13;
  padding-bottom: 60px;
}

div.wrapper section.home-section-2 > div div.process-section {
  grid-row: 2;
  grid-column: 2 / 12;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  grid-gap: 24px;
  grid-row-gap: 32px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

div.wrapper section.home-section-2 > div div.process-section > div {
  border: #1e1e1e 1px solid;
}

div.wrapper section.home-section-2 > div h2:first-of-type {
  grid-row: 1;
  grid-column: 2 / 12;
  text-align: center;
  margin-top: 60px;
}

div.wrapper section.home-section-2 > div h2:nth-of-type(2) {
  grid-row: 3;
  grid-column: 2 / 12;
  text-align: center;
  margin-top: 60px;
}

div.wrapper section.home-section-2 > div div.guide-section {
  grid-row: 4;
  grid-column: 2 / 12;
  display: grid;
  grid-row-gap: 32px;
  margin-top: 20px;
  margin-bottom: 100px;
  width: 100%;
}

div.wrapper section.home-section-3 {
  display: none;
}

div.wrapper section.home-section-4 {
  padding: 120px 0 0 0;
}

div.wrapper section:nth-of-type(4) > div {
  background: #2d62ed;
  border-radius: 16px;
  grid-column: 1 / 13;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
}

div.wrapper section:nth-of-type(4) > div > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: 2 / 12;
  padding: 56px 0;
}

div.highlighted-build {
  height: 160px;
  position: relative;
}

div.highlighted-build > img {
  position: absolute;
  right: -80px;
  bottom: -56px;
  border-radius: 8px;
  max-height: 300px;
  width:460px;
  object-fit: cover;
  object-position: 100% 0;
  overflow: hidden;
}

@media only screen and (min-width: 1920px) {
  /* # */
}

@media only screen and (max-width: 1270px) {
  div.wrapper section.home-section-1 div.fold-section {
    /* padding: 32px; */
    height: 100%;
  }

  div.wrapper section.home-section-1 div.fold-section > p {
    width: 80%;
  }

  div.highlighted-build {
    height: 220px;
    right: -40px;
    width: 50%;
    position: relative;
  }
  div.highlighted-build > img {
    position: relative;
    right: 0px;
    bottom: 36px;
    width: auto;
  }
}

@media only screen and (max-width: 940px) {
  div.wrapper section.home-section-1 div.fold-section {
    padding: 16px;
  }
  div.wrapper section.home-section-1 div.fold-section > p {
    margin-bottom: 0;
  }
  div.highlighted-build {
    right:0;
    width: 100%;
  }
  div.highlighted-build img {
    position: relative;
    width: 100%;
    top: 0;
    bottom: 0;
    right: -20px;
  }
}
@media screen and (max-width: 576px) {
  div.header-nav {
    position:sticky;
  }
  div.wrapper section.home-section-2 > div div.process-section {
    display:flex;
    flex-direction: column;
  }
  div.wrapper section.home-section-1 div.fold-section h1 {
    font-size:28px;
  }

  div.wrapper section:nth-of-type(1) {
    padding-bottom:100px;
  }

  div.wrapper section.home-section-2 > div h2:first-of-type {
    margin-top:40px;
  }

  div.wrapper section.home-section-2 > div div.process-section {
    margin-top:20px;
    margin-bottom:20px
  }

  .process-card > div > span {
    font-size:14px;
    font-weight:bold!important;
  }

  div.wrapper section.home-section-2 > div h2:nth-of-type(2) {
    margin-top:40px
  }

  h2 {
    font-size: 24px;
  }

  .CTA_two{
    flex-direction: column-reverse;
    }

  div.highlighted-build {
    width: auto;
    right:0;
    height: 60%;
  }

  div.highlighted-build img {
    width: 100%;
    height: 100%;
  }
}
footer {
  max-width: 1200px;
  margin: auto;
}

.spacer {
  height: 120px;
}

.container {
  background-color: #1E1E1E;
  border-radius: 16px 16px 0 0;
  padding: 64px 0 0 0;
  display: grid;
  grid-column: 1 / 13;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
}

.container .footer-column {
  display: flex;
  flex-direction: column;
}

.container .footer-column:nth-of-type(1) {
  grid-column: 2 / 6;
}

.container .footer-column:nth-of-type(2) {
  grid-column: 6 / 9;
}

.container .footer-column:nth-of-type(3) {
  grid-column: 9 / 12;
}

.container .footer-column:first-child h4 {
  margin-top: 16px;
}

.container .footer-column h5 {
  font-size: 28px;
  padding-bottom: 8px;
}

.container .footer-column ol {
  list-style-type: none;
}

.container .footer-column .logo {
  margin-bottom: 16px;
  width: auto;
  max-width: 265px;
}

.socials {
  display: flex;
  justify-content: flex-start;
  margin-left: 70px;
  gap: 8px;
}

.socials a {
  color: white;
}

.socials span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #3D3D3D;
}

.socials span:hover {
  background-color: #F75578;
}

.rights {
  background-color: #1E1E1E;
  border-radius: 0 0 16px 16px;
  color: #848484;
  display: flex;
  justify-content: center;
  padding: 32px 0 8px 0;
}

/* mobile portrait */

@media only screen and (max-width: 1270px) {
  footer {
    max-width: 900px;
  }
}

@media only screen and (max-width: 940px) {
  footer {
    max-width: 800px;
    padding: 0 16px;
  }
}

@media only screen and (max-width: 768px) {
  .container .footer-column {
    width: 200px;
    margin: auto;
    grid-column: 2 / 12!important;
  }
  .container .footer-column .socials {
    margin: 0;
  }
  .container .footer-column .socials {
    margin: 0;
  }
}